import React from 'react';
import { NavLink } from 'react-router-dom';
import './navbar.css'; // Import CSS for styling

const NavBar = () => {
  return (
    <header className="header">
    <h3>neuralglance</h3>
      <nav className="navbar">
        <div className="container">
          <div className="nav-elements">
            <ul>
              <li>
                {/* <NavLink exact to="/" activeClassName="active">Home</NavLink> */}
              </li>
              <li>
                <NavLink to="/setup" activeClassName="setup">Setup</NavLink>
              </li>
              {/* <li>
                <NavLink to="/portfolio" activeClassName="active">Portfolio</NavLink>
              </li> */}
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default NavBar;
