import React, { Component } from 'react';

const styles = {
  formContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    marginTop: '20px',
    color: '#FFFFFF'
  },
  form: {
    backgroundColor: '#333',
    padding: '20px',
    borderRadius: '8px',
    color: '#FFFFFF',
  },
  formGroup: {
    marginBottom: '15px',
  },
  label: {
    display: 'block',
    marginBottom: '8px',
    color: '#FFFFFF',
  },
  input: {
    width: '100%',
    padding: '8px',
    boxSizing: 'border-box',
    borderRadius: '4px',
    border: '1px solid #dddddd',
    backgroundColor: '#555',
    color: '#FFFFFF',
  },
  select: {
    width: '100%',
    padding: '8px',
    boxSizing: 'border-box',
    borderRadius: '4px',
    border: '1px solid #dddddd',
    backgroundColor: '#555',
    color: '#FFFFFF',
  },
  button: {
    padding: '10px 20px',
    border: 'none',
    borderRadius: '4px',
    backgroundColor: '#000',
    color: '#FFFFFF',
    cursor: 'pointer',
  },
  buttonHover: {
    backgroundColor: '#444',
  }
};

class BasicForm extends Component {
  constructor(props) {
    super(props);
    // Initialize state with an object where keys are field names
    const initialState = props.fields.reduce((state, field) => {
      state[field.name] = field.type === 'select' ? (field.options[0] || '') : '';
      return state;
    }, {});
    
    this.state = {
      formValues: initialState,
    };
  }

  handleChange = (e) => {
    this.setState({
      formValues: {
        ...this.state.formValues,
        [e.target.name]: e.target.value
      }
    });
  }

  handleSubmit = (e) => {
    e.preventDefault();
    // Call the onSubmit prop with the form values
    if (this.props.onSubmit) {
      this.props.onSubmit(this.state.formValues);
    }
  }

  render() {
    const { fields, width } = this.props;
    const { formValues } = this.state;

    return (
      <div style={{ ...styles.formContainer, width: width || 'auto' }}>
        <form style={{ ...styles.form, width: width || 'auto' }} onSubmit={this.handleSubmit}>
          {fields.map((field, index) => (
            <div style={styles.formGroup} key={index}>
              <label style={styles.label} htmlFor={field.name}>{field.label}</label>
              {field.type === 'select' ? (
                <select
                  id={field.name}
                  name={field.name}
                  value={formValues[field.name]}
                  onChange={this.handleChange}
                  style={styles.select}
                >
                  {field.options.map((option, optionIndex) => (
                    <option key={optionIndex} value={option}>{option}</option>
                  ))}
                </select>
              ) : (
                <input
                  type={field.type}
                  id={field.name}
                  name={field.name}
                  value={formValues[field.name]}
                  onChange={this.handleChange}
                  style={styles.input}
                />
              )}
            </div>
          ))}
          <button type="submit" style={styles.button}>Submit</button>
        </form>
      </div>
    );
  }
}

export default BasicForm;
