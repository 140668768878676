import React, { Component } from 'react';
import './App.css';
import ScraperSetup from './components/scraperSetup';
import NavBar from './components/navbar';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Authenticator, ForgotPassword } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import { getCurrentUser, decodeJWT, fetchAuthSession, signOut } from '@aws-amplify/auth';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      authState: false,

    }
  }

  authStatus = async() => {
    try {
      const response = await getCurrentUser();
      this.setState({authState: response})
      return true;
    }
    catch {
      return false;
    };
  };

  async componentDidMount(){
    if(this.authStatus()) {
      const session = await fetchAuthSession(); 
      this.getIdToken()
    }
  }

  render() {
    // const authStatus = this.authStatus()
    const { authState } = this.state
    if (authState) {
      return (
        <BrowserRouter>
          <NavBar />
          <Routes>
            <Route path="/" element={<ScraperSetup />} />
            <Route path="/setup" element={<ScraperSetup  />} />
            <Route path="*" element={<ScraperSetup />} />
          </Routes>
        </BrowserRouter>
      );
    }
    else if (!authState) {
      return (
        <div>
          <Authenticator />
        </div>
      )
    }
  }
}

export default App;
