import React, { Component } from 'react';

const styles = {
  gridContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    marginTop: '20px',
    color: '#FFFFFF'

  },
  table: {
    borderCollapse: 'collapse',
    width: '80%',
    color: '#FFFFFF'
  },
  th: {
    border: '1px solid #dddddd',
    textAlign: 'left',
    padding: '8px',
    backgroundColor: '#000',
    position: 'relative',
  },
  td: {
    border: '1px solid #dddddd',
    textAlign: 'left',
    padding: '8px',
    color: '#FFFFFF',
    backgroundColor: '#333'
  },
  input: {
    width: '100%',
    boxSizing: 'border-box',
    padding: '6px',
  },
  select: {
    width: '100%',
    boxSizing: 'border-box',
    padding: '6px',
  },
  resizer: {
    position: 'absolute',
    right: 0,
    top: 0,
    bottom: 0,
    width: '5px',
    cursor: 'col-resize',
    backgroundColor: '#f2f2f2',
  },
};

class DataGrid extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editingCell: null, // Track the currently edited cell
      columnWidths: {}, // Track column widths
      resizing: false,
      startX: 0,
      startWidth: 0,
      resizableColumnKey: null,
    };
  }

  handleInputChange = (id, key, value) => {
    const updatedData = this.props.data.map(item =>
      item.id === id ? { ...item, [key]: value } : item
    );
    this.props.onDataChange(updatedData); // Call the callback to update the data in the parent component
  };

  handleDoubleClick = (id, key) => {
    this.setState({ editingCell: { id, key } });
  };

  handleBlur = () => {
    this.setState({ editingCell: null });
  };

  handleMouseDown = (e, key) => {
    this.setState({
      resizing: true,
      startX: e.clientX,
      startWidth: this.state.columnWidths[key] || 150,
      resizableColumnKey: key,
    });
  };

  handleMouseMove = (e) => {
    if (this.state.resizing) {
      const { startX, startWidth, resizableColumnKey } = this.state;
      const newWidth = startWidth + (e.clientX - startX);
      this.setState((prevState) => ({
        columnWidths: {
          ...prevState.columnWidths,
          [resizableColumnKey]: newWidth,
        },
      }));
    }
  };

  handleMouseUp = () => {
    this.setState({ resizing: false, resizableColumnKey: null });
  };

  render() {
    const { data, headerMapping, editableFields, dropdownFields } = this.props;
    const { editingCell, columnWidths } = this.state;
    const headers = Object.keys(headerMapping);

    return (
      <div style={styles.gridContainer} onMouseMove={this.handleMouseMove} onMouseUp={this.handleMouseUp}>
        <table style={styles.table}>
          <thead>
            <tr>
              {headers.map(key => (
                <th key={key} style={{ ...styles.th, width: columnWidths[key] || 150 }}>
                  {headerMapping[key]}
                  <div
                    style={styles.resizer}
                    onMouseDown={(e) => this.handleMouseDown(e, key)}
                  />
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data.map(item => (
              <tr key={item.id}>
                {headers.map(key => (
                  <td
                    key={key}
                    style={{ ...styles.td, width: columnWidths[key] || 150 }}
                    onDoubleClick={() => this.handleDoubleClick(item.id, key)}
                  >
                    {editableFields.includes(key) && editingCell && editingCell.id === item.id && editingCell.key === key ? (
                      dropdownFields && dropdownFields[key] ? (
                        <select
                          value={item[key]}
                          style={styles.select}
                          onChange={(e) => this.handleInputChange(item.id, key, e.target.value)}
                          onBlur={this.handleBlur}
                          autoFocus
                        >
                          {dropdownFields[key].map(option => (
                            <option key={option} value={option}>{option}</option>
                          ))}
                        </select>
                      ) : (
                        <input
                          type="text"
                          value={item[key]}
                          style={styles.input}
                          onChange={(e) => this.handleInputChange(item.id, key, e.target.value)}
                          onBlur={this.handleBlur}
                          autoFocus
                        />
                      )
                    ) : (
                      item[key]
                    )}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }
}

export default DataGrid;
