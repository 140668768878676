import React, { Component } from 'react';
import DataGrid from '../sharedComponents/dataGrid';
import BasicForm from '../sharedComponents/basicForm';

const inputForm = [
  { label: 'Scrape Name', type: 'text', name: 'name' },
  { label: 'URL', type: 'text', name: 'url' },
  // { label: 'Password', type: 'password', name: 'password' },
  { label: 'Method', type: 'select', name: 'method', options: ['BASIC', 'JAVASCRIPT'] }
];

class ScraperSetup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [
        { scrape_id: 'TCG', url: '', age: 28, method: 'BASIC' },
        { id: 2, name: 'Jane Smith', age: 34, method: 'BASIC' },
        { id: 3, name: 'Mike Johnson', age: 45, method: 'BASIC' },
      ],
      headerMapping: {
        scrape_id: 'Scrape ID',
        url: 'URL',
        method: 'Method',
      },
      editableFields: ['scrape_id', 'url', 'method'], // Fields that are editable
      dropdownFields: {
        method: ['BASIC', 'BASIC_REGEX_HREF', 'JAVASCRIPT_BASIC', 'JAVASCRIPT_REGEX_HREF'], // Dropdown options for the role field
      },
    };
  }

  handleDataChange = (updatedData) => {
    this.setState({ data: updatedData });
    console.log(updatedData)
  };

  handleFormSubmit = (formValues) => {
    console.log('Form submitted with:', formValues);
  }

  render() {
    const { data, headerMapping, editableFields, dropdownFields } = this.state;

    return (
      <div>
        <BasicForm 
          fields={inputForm} 
          onSubmit={this.handleFormSubmit} 
          width="600px"
        />
        {/* <DataGrid
          data={data}
          headerMapping={headerMapping}
          editableFields={editableFields}
          dropdownFields={dropdownFields}
          onDataChange={this.handleDataChange}
        /> */}
      </div>
    );
  }
}

export default ScraperSetup;
